import React, { useEffect } from "react";
import "./App.css";
import Header from "./Components/Header/header";
import Footer from "./Components/Footer/footer";
import Audios from "./Components/Audio/audios";
import Home from "./Components/Home/home";
import ImageGallery from "./Components/ImageGallery/ImageGallery";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Page_404 from "./Components/404";
import Books from "./Components/Books/Books";
import AudioDetail from "./Components/Audio/audioDetail";
import bookDetail from "./Components/Books/bookDetail";
import KhanqahIntroduction from "./Pages/khanqahIntroduction";
import Information from "./Pages/information";
import AnnounceList from "./Components/Announcement/AnnounceList";
import AnnouncementDetail from "./Components/Announcement/AnnouncementDetail";
import MzShajraetariqat from "./Pages/mzShajraetariqat";
import MzSanadehadees from "./Pages/mzSanadehadees";
import AkTaaruf from "./Pages/akTaaruf";
import AkShajraetariqat from "./Pages/akShajraetariqat";
import AkSanadehadees from "./Pages/akSanadehadees";
import Khatoot from "./Pages/khatoot";
import Contact from "./Pages/contact";
import SearchAll from "./Components/Search/SearchAll";
import { getFCMToken } from "./firebase";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import { firebaseConfig } from "../src/firebase";
import useNotification from "./Components/Hooks/useNotification";
import Notification from "./Components/Notification/notification";
import Live1 from "./Components/Live/gulistanLive";
import Live2 from "./Components/Live/gulshanLive";

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

function App() {
  const { setNotification } = useNotification();
  useEffect(() => {
    let reg = localStorage.getItem("register");
    if (!reg) {
      getFCMToken();
    }
    onMessage(messaging, ({ notification }) => {
      console.log(notification);
      setNotification(notification);
    });
  }, []);

  return (
    <>
      <BrowserRouter>
        <Header />

        <Switch>
          <Route exact path="/" component={Home} />
          <Route
            exact
            path="/khanqah-Imdadia-sindh-baloch-live"
            component={Live1}
          />
          <Route exact path="/khanqah-Imdadia-gulshan-live" component={Live2} />
          <Route
            exact
            path="/audios/:author/:category?/:filter_url?/"
            component={Audios}
          />
          <Route exact path="/audio/:slug" component={AudioDetail} />
          <Route
            exact
            path="/books/:author/:category?/:filter_url?"
            component={Books}
          />
          <Route exact path="/book/:slug" component={bookDetail} />
          <Route
            exact
            path="/gallery/:category/:filter_url?/"
            component={ImageGallery}
          />
          <Route exact path="/search/:filter_url/" component={SearchAll} />
          /* PAGES */
          <Route
            exact
            path="/page/khanqahIntroduction/"
            component={KhanqahIntroduction}
          />
          <Route
            exact
            path="/page/:author/:category/"
            component={Information}
          />
          <Route
            exact
            path="/announcement/:category/:filter_url?"
            component={AnnounceList}
          />
          <Route
            exact
            path="/announcement-detail/:id"
            component={AnnouncementDetail}
          />
          <Route
            exact
            path="/page/mzShajra-e-tariqat/"
            component={MzShajraetariqat}
          />
          <Route
            exact
            path="/page/mzSanad-e-hadees/"
            component={MzSanadehadees}
          />
          <Route exact path="/page/akTaaruf/" component={AkTaaruf} />
          <Route
            exact
            path="/page/akShajra-e-tariqat/"
            component={AkShajraetariqat}
          />
          <Route
            exact
            path="/page/akSanad-e-hadees/"
            component={AkSanadehadees}
          />
          <Route exact path="/page/khatoot/" component={Khatoot} />
          <Route exact path="/page/contact/" component={Contact} />
          <Route component={Page_404} />
        </Switch>
        <Footer />
      </BrowserRouter>
      <Notification />
    </>
  );
}

export default App;
