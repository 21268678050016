import React from "react";
import playIcon from "../../Images/play.png";
import Gif from "../../Images/loader.gif";
import { Helmet } from "react-helmet";
import SocialSharing from "../socialSharing/SocialSharing";
import useFetch from "../Hooks/useFetch";
import AudioPlayer from "react-h5-audio-player";


const AudioDetail = () => {
  const { dataList, loader } = useFetch("audio");

  return (
    <>
      <div className={loader ? "onloadAnimate" : "onloadAnimate loaded"}>
        <img src={Gif} alt="Loading spinner" />
      </div>
      {dataList.count > 0 && !loader ? (
        <>
          <Helmet>
            <title>
              {dataList.results[0].title_en} - Khanqah Imdadia Ashrafia
            </title>
            <meta name="title" content={dataList.results[0].title_en} />
            <meta
              name="description"
              content={dataList.results[0].description_en}
            />
          </Helmet>
          <div className="pageTitle">
            <h1 className="detailTitle">{dataList.results[0].author.name_ur}</h1>
          </div>
          <div className="container">
            <div className="row activeAudio">
              <div className="col-md-3">
                <div className="left_col">
                  <SocialSharing link={"/audio/"+dataList.results[0].slug} />
                  {/* <div className="playIcon">
                    <img src={playIcon} alt="" />
                  </div> */}
                  <img
                    src={dataList.results[0].files[0].thumbnail}
                    alt=""
                    className="thumb"
                  />
                    <AudioPlayer
                      src={dataList.results[0].files[0].file}
                      showJumpControls={false}
                    />
                </div>
              </div>
              <div className="col-md-6">
                <div className="right_col">
                  <h1>{dataList.results[0].title_ur}</h1>
                  <p>{dataList.results[0].description_ur}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="pageTitle">
          <h1>No Data Found</h1>
        </div>
      )}
    </>
  );
};

export default AudioDetail;
