import React, { useState, useEffect } from "react";
import logo from "../../Images/logo.png";
import logo_bg from "../../Images/logo_bg.png";
import menu_bg from "../../Images/menu_bg.png";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { navMenu } from "../api";

const Header = () => {
  const [Nav, setNav] = useState([]);
  const [SearchValue, setSearchValue] = useState();
  const [SearchType, setSearchType] = useState("search");
  const [hamBurger, setHamBurger] = useState(false);
  useEffect(() => {
    navMenu()
      .then((res) => {
        setNav(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const history = useHistory();
  const Search = (e) => {
    e.preventDefault();
    history.push("/" + SearchType + "/&search=" + SearchValue);
  };
  const UrlHit = (e) => {
    if (e === "https://khanqah.org/alabrar/") {
      window.location = e;
    }
  };

  return (
    <>
      <div className="Top_bar">
        <div className="container">
          <div className="Search_bar">
            <form>
              <button
                type="submit"
                onClick={(e) => {
                  Search(e);
                }}
              >
                <i className="fa fa-search" aria-hidden="true"></i>
              </button>
              <input
                type="text"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                placeholder="تلاش کریں"
              ></input>
              |
              <select
                onChange={(e) => {
                  setSearchType(e.target.value);
                }}
              >
                <option value="search">مکمل</option>
                <option value="audios">صوتیات</option>
                <option value="books">ذخیرہ کتب</option>
                <option value="gallery"> حکمت کے موتی</option>
              </select>
            </form>
            {hamBurger ? (
              <div className="hamBurger">
                <i
                  className="fa fa-close"
                  aria-hidden="true"
                  onClick={() => setHamBurger(false)}
                ></i>
              </div>
            ) : (
              <div className="hamBurger">
                <i
                  className="fa fa-bars"
                  aria-hidden="true"
                  onClick={() => setHamBurger(true)}
                ></i>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="Logo"
        style={{
          backgroundImage: `url(${logo_bg})`,
        }}
      >
        <Link to="/">
          <img alt="" src={logo} />
        </Link>
      </div>
      <div className="container">
        <div
          className="MenuList"
          style={{
            backgroundImage: `url(${menu_bg})`,
            right: window.innerWidth <= 768 && (hamBurger ? "0px" : "-150%"),
          }}
        >
          <ul className="col_reverse">
            {Nav.map((data) => (
              <>
                <li key={data.id}>
                  {data.children != "" && (
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                  )}
                  <Link to="#" onClick={() => UrlHit(data.path)}>
                    {data.name}
                  </Link>
                  {data.children && (
                    <ul className="firstLevel">
                      {data.children.map((child, index) => (
                        <li key={child.id}>
                          {child.sub_children != "" && (
                            <i
                              className="fa fa-angle-left"
                              aria-hidden="true"
                            ></i>
                          )}
                          <Link
                            to={{
                              pathname:
                                window.innerWidth <= 768
                                  ? data.id === 145 ||
                                    data.id === 140 ||
                                    data.id === 79
                                    ? child.path
                                    : ""
                                  : child.path,
                            }}
                            alt=""
                            onClick={() =>
                              setHamBurger(
                                data.id === 145 ||
                                  data.id === 140 ||
                                  data.id === 79
                                  ? false
                                  : true
                              )
                            }
                          >
                            {child.name}
                          </Link>
                          {child.sub_children && (
                            <ul className="secondLevel">
                              {child.sub_children.map((sub_child) => (
                                <li key={sub_child.id}>
                                  <Link
                                    onClick={() => setHamBurger(false)}
                                    to={{
                                      pathname: sub_child.path,
                                    }}
                                    alt=""
                                  >
                                    {sub_child.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              </>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
