import React from "react";
import axios from "axios";
import { BASE_URL } from "../baseUrl";


export const newsLetter = async (data) => {
    return await axios.post(`${BASE_URL}/sbscription/`, data);
}

export const navMenu = async () => {
    return await axios.get(`${BASE_URL}/navigation/`);
}