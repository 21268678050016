import React, { useEffect, useState } from "react";
import Banner from "./Banner";
import Gif from "../../Images/loader.gif";
import NavButtons from "./NavButtons";
import ShortClips from "./ShortClips";
import Imageslide from "./Imageslide";
import FeaturedData from "./FeaturedData";
import { Helmet } from "react-helmet";
import duaenBg from "../../Images/duaen_bg.jpg";
import sunnatenBg from "../../Images/m_sotiyat_bg.jpg";
import asbaaqBg from "../../Images/asbaaqBg.jpg";
import asbaaqBgMob from "../../Images/asbaaqBgMob.jpeg";
// import nayaShumaraBg from "../../Images/nayashumaraBg.jpeg"
import useHome from "./useHome";
import AhamBayan from "./AhamBayan";
import { closePopup } from "../Hooks/useFunctions";
import HubspotContactForm from "./HubspotContactForm";

const Home = () => {
  const { Home, loader } = useHome();
  const [modal, setModal] = useState(false);
  const [live, setLive] = useState(false);

  useEffect(() => {
    // axios.get("http://live.khanqah.org:8002/live").then((res) => {
    //   setLive(true);
    //   consle.log(res);
    // });
    // let pop_status = localStorage.getItem('pop_status');
    // if(!pop_status){
    //   setModal(true);
    //   localStorage.setItem('pop_status',1);
    // }
    function handleScroll() {
      if (window.scrollY > 1000) {
        let pop_status = localStorage.getItem("pop_status");
        if (!pop_status) {
          setModal(true);
          localStorage.setItem("pop_status", 1);
        }
        window.removeEventListener("scroll", handleScroll);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Khanqah Imdadia Ashrafia</title>
        <meta name="title" content="Khanqah Imdadia Ashrafia" />
        <meta
          name="description"
          content="Fughan e Akhtar Khanqah Imdadia Ashrafia Karachi, Sheikh Hakeem Akhtar R.A Khanqah Imdadia Ashrafia Gulshan e Iqbal Karachi Fughan e Akhtar Khanqah Imdadia"
        />
      </Helmet>
      <div className={loader ? "onloadAnimate" : "onloadAnimate loaded"}>
        <img src={Gif} alt="Loading spinner" />
      </div>
      {!loader ? (
        <>
          {modal && <HubspotContactForm onClick={() => setModal(false)} />}
          <Banner slider={Home.slider} />
          <NavButtons live={live} />
          <div
            className="imageBg naye_kitab"
            style={{
              backgroundImage: `url(${
                window.innerWidth <= 768 ? asbaaqBgMob : asbaaqBg
              })`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6 newBook">
                  <FeaturedData text={Home.Naye_Kitab} />
                </div>
                <div className="col-md-6">
                  <FeaturedData text={Home.Aj_ka_sabaq} />
                </div>
              </div>
            </div>
          </div>

          <div
            className="imageBg"
            style={{ backgroundImage: `url(${sunnatenBg})` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6 white">
                  <FeaturedData text={Home.Aj_ka_malfooz} />
                </div>
                <div className="col-md-6 white">
                  <AhamBayan text={Home.Aham_Bayan} />
                </div>  
              </div>
            </div>
          </div>

          <div
            className="imageBg naya_shumara"
            style={{
              backgroundImage: `url(${
                window.innerWidth <= 768 ? asbaaqBgMob : asbaaqBg
              })`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <FeaturedData text={Home.Naya_Shumara} />
                </div>
                <div className="col-md-6">
                  <FeaturedData text={Home.Khanqah_k_shab_e_rooz} />
                </div>
              </div>
            </div>
          </div>

          <ShortClips clips={Home.Mukhtasar_Sootiat} />
          <div
            className="imageBg featuredImage bgRemove"
            style={{ backgroundImage: `url(${duaenBg})` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <Imageslide Gallery={Home.Duaen} />
                </div>
                <div className="col-md-6">
                  <Imageslide Gallery={Home.Kashkool_e_Maarifat} />
                </div>
              </div>
            </div>
          </div>
          <div
            className="imageBg featuredImage"
            style={{ backgroundImage: `url(${sunnatenBg})` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6 white">
                  <Imageslide Gallery={Home.sunnatien} />
                </div>
                <div className="col-md-6 white">
                  <Imageslide Gallery={Home.islahi_parche} />
                </div>
              </div>
            </div>
          </div>
          <div id="fullImage">
            <img src="" alt="" id="changeSrc" />
            <span
              id="closeBtn"
              onClick={() => {
                closePopup();
              }}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </span>
          </div>
          {live && (
            <a href="http://live.khanqah.org:8002/live" target="_blank">
              <div className="liveGif">
                <iframe
                  src="https://giphy.com/embed/2A2DRLcRSCWtvNBv1f"
                  frameBorder="0"
                ></iframe>
              </div>
            </a>
          )}
        </>
      ) : (
        <div className="pageTitle">
          <h1>No Data Found</h1>
        </div>
      )}
    </>
  );
};

export default Home;
