import React from "react";
import { useHistory, useParams } from "react-router-dom";

const YearFilter = ({ years }) => {
  const { filter_url } = useParams();
  const history = useHistory();

  const Filter = () => {
    var year = document.getElementById("fYear").value;
    var duration = document.getElementById("fDuration").value;
    localStorage.setItem("year", year);
    history.push(year + duration);
  };

  if (!filter_url) {
    localStorage.setItem("year", "");
  }

  return (
    <div className="fYear">
      <select
        id="fYear"
        value={localStorage.getItem("year")}
        onChange={() => {
          Filter();
        }}
      >
        <option value=" ">Filter By Year</option>
        {years?.map((data) => (
          <option value={`&recorded_year=${data}`} key={data}>
            {data}
          </option>
        ))}
      </select>
      <i className="fa fa-filter" aria-hidden="true"></i>
    </div>
  );
};

export default YearFilter;
