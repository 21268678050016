import React, { useState } from "react";
import Footer_bg from "../../Images/footer_bg.png";
import Email from "../../Images/email.png";
import Facebook from "../../Images/facebook.png";
import twitter from "../../Images/twitter.png";
import whatsapp from "../../Images/whatsapp.png";
import tellygram from "../../Images/tellygram.png";
import Logo from "../../Images/footer_logo.png";
import { Link } from "react-router-dom";
import { newsLetter } from "../api";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const onSubmit = (event) => {
    event.preventDefault();
    newsLetter({ email: email })
      .then(() => {
        setEmail("");
        setMessage("آپ کا پیغام جمع کر دیا گیا ہے");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div
      className="footer_section"
      style={{
        backgroundImage: `url(${Footer_bg})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-4 footer_logo">
            <img src={Logo} />
            <p>:مرکز دفتر</p>
            <p style={{ lineHeight: "1.5" }}>
              خانقاہ امدادیہ اشرفیہ، گلشن اقبال ، بلاک نمبر 2، نزد ربّ میڈیکل
              سینٹر، کراچی
            </p>
            <p>
              <a href="tel:03212091000">+92-321-2091000</a> &nbsp;|&nbsp;
              +92-21-34975758 &nbsp;|&nbsp; +92-21-34975658 : فون
            </p>
          </div>
          <div className="col-md-5 news_letter">
            <p>
              خانقاہ امدادیہ اشرفیہ کراچی کی ویب سائٹ سے متعلق کسی بھی قسم کے
              مشورے اور لنک کی خرابی کے سلسلے میں اطلاع دیں ای میل کیجیے
            </p>
            <form onSubmit={onSubmit}>
              <div className="email_subscribe">
                <button type="submit">
                  <img src={Email} />
                </button>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="ای میل"
                  required
                  onKeyDown ={(event) =>
                    event.key === "Enter" && onSubmit()
                  }
                ></input>
                <span className="formMessage">{message}</span>
              </div>
            </form>
            <p>ہمارے سوشل میڈیا کو جوائن کرنے کے لیے نیچے کلک کریں</p>
            <a href="https://twitter.com/khanqahashrafia">
              <img src={twitter} />
            </a>
            <a href="https://t.me/KhanqahImdadiaAshrafia">
              <img src={tellygram} />
            </a>
            <a href="https://api.whatsapp.com/send?phone=03212091000">
              <img src={whatsapp} />
            </a>
          </div>
          <div className="col-md-2 ft_menu">
            <h1>نقشہ سائٹ</h1>
            <ul>
              <li>
                <Link to="/audios/hazrat-molana-hakeem-muhammad-akhtar-rh/bayanat/">
                  صوتیات
                </Link>
              </li>
              <li>
                <Link to="/page/moulana-hakeem-mazhar-sahib/taaruf/">
                  تعارفِ اکابر
                </Link>
              </li>
              <li>
                <Link to="/page/khanqah/taaruf/">خانقاہ</Link>
              </li>
              <li>
                <Link to="/books/hazrat-molana-hakeem-muhammad-akhtar-rh/mawaiz-e-hasanah/">
                  ذخیرہ کتب
                </Link>
              </li>
              <li>
                <Link to="/audios/hazrat-molana-hakeem-muhammad-akhtar-rh/audio-kutub/">
                  آڈیو کتب
                </Link>
              </li>
              <li>
                <Link to="/books/author/al-abrar/">رسائل</Link>
              </li>
              {/* <li><Link to="">خطوط</Link></li> */}
            </ul>
          </div>
        </div>
        <div className="col-md-12 copyright">
          <p>
            Powered by <a href="https://gybcommerce.com/">GYBCommerce.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
