import React, { useState, useEffect } from "react";
import axios from "axios";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import Gif from "../../Images/loader.gif";
import { BASE_URL } from "../../baseUrl";
import ReactHtmlParser from "react-html-parser";

const AnnouncementDetail = () => {
  const { id } = useParams();
  const [Content, setContent] = useState();
  const [Loader, setLoader] = useState(true);
  console.log(Content);
  useEffect(() => {
    setLoader(true);
    axios
      .get(
        `${BASE_URL}/announcement/${id}/`
      )
      .then((response) => {
        setContent(response.data);
        setLoader(false);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  }, [id]);
  return (
    <>
      <div className={Loader ? "onloadAnimate" : "onloadAnimate loaded"}>
        <img src={Gif} alt="Loading spinner" />
      </div>
      {Content && !Loader ? (
        <>
          <Helmet>
            <title>{Content.category.name} - Khanqah Imdadia Ashrafia</title>
            <meta name="description" content="dummy text" />
          </Helmet>
          <div className="pageTitle">
            <h1>{Content.category.name_ur}</h1>
          </div>
          <div className="container textPages">
            <div className="row">
                <div className="col-md-12">
                    <p className="ReactHtmlParser">
                      {ReactHtmlParser(Content.description_ur)}
                    </p>
                </div>
            </div>
          </div>
        </>
      ) : (
        <div className="pageTitle">
          <h1>No Data Found</h1>
        </div>
      )}
    </>
  );
};

export default AnnouncementDetail;
 