import React from "react";
import { Helmet } from "react-helmet";

const MzShajraetariqat = () => {
  return (
    <>
      <Helmet>
        <title>تعارف - Khanqah Imdadia Ashrafia</title>
        <meta name="description" content="dummy text" />
      </Helmet>
      <div class="pageTitle">
        <h1>
          حکیم الامت حضرت اقدس مولانا شاہ حکیم محمد مظہر صاحب دامت برکاتہم
        </h1>
      </div>
      <div className="container textPages">
        <div className="row">
          <div className="col-md-12">
            <div>
              <h1 style={{marginTop:'2rem'}}>شجرہ طریقت</h1>
              <p>
                سیدنا ومولانا حضرت محمد مصطفی صلی اللہ علیہ وسلم <br></br>
                امیر المؤمنین سیدناحضرت علی المرتضیٰ رضی اللہ عنہ <br></br>
                حضرت الشیخ خواجہ حسن بصری رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ عبد الواحد بن زید رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ فضیل بن عیاض رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ سلطان ابراہیم بن ادہم بلخی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ حذیفہ مرعشی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ امین الدین ابی حبیرہ بصری رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ سید ممشاد علوی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ سید ابی اسحاق شامی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ سید ابی احمد ابدال چشتی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ سید ابی محمد محترم چشتی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ سید ابی یوسف چشتی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ سید مودود چشتی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ الحاج سید شریف زندانی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ سید عثمان ھارونی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ معین الدین حسن سنجری چشتی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ قطب الدین بختیار کاکی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ فرید الدین شکر گنج وجدانی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ علاؤ الدین علی احمد صابر کلیری رحمۃ اللہ علیہ{" "}
                <br></br>
                حضرت الشیخ شمس الدین تُرکی پانی پتی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ جلال الدین کبیر الاولیاء پانی پتی رحمۃ اللہ علیہ{" "}
                <br></br>
                حضرت الشیخ احمد عبد الحق ردولوی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ احمد عارف الردولوی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ محمد عارف الردولوی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ قطب عالم عبد القدوس گنگوہی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ جلال الدین تھانیسری رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ نظام الدین بلخی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ ابی سعد گنگوہی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ محب اللہ اِلٰہ آبادی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ شاہ محمدی اکبر آبادی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ محمد مکی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ شاہ عضد الدین رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ شاہ عبد الہادی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ عبد الباری امروہی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ عبد الرحیم شہید رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ نور محمد جھنجھانوی رحمۃ اللہ علیہ <br></br>
                حضرت الشیخ الحاج الحافظ امداد اللہ مہاجر مکی رحمۃ اللہ علیہ{" "}
                <br></br>
                حضرت الشیخ حکیم الامت مولانا اشرف علی تھانوی رحمۃ اللہ علیہ{" "}
                <br></br>
                حضرت الشیخ محیی السنہ مولانا ابرار الحق ہردوئی <br></br>
                حلیم الامت حضرت اقدس مولانا شاہ حکیم محمد مظہر صاحب دامت برکاتہم
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MzShajraetariqat;
