import { React, useState, useEffect } from "react";
import axios from "axios";
import API_URL from "../../baseUrl";


const useFilter = () => {
  const [FilterList, setFilterList] = useState([]);
  const{FilterUrl} = API_URL();
  useEffect(() => {
    axios.get(FilterUrl)
      .then((response) => {
        setFilterList(response.data);
      })
      .catch((error) => {
        console.log("There was an error!", error);
      });
  }, [FilterUrl]);

  return {FilterList};
};

export default useFilter;
