import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";




export const FullImage = (src) => {
  document.getElementById("changeSrc").src = src;
  document.getElementById("fullImage").style.display = "block";
};

export const closePopup = () => {
  document.getElementById("fullImage").style.display = "none";
};


// export const Play = () => {
//   var play = document.getElementById("playIcon");
//   var icon = play.getElementsByClassName("icon");
//   for (var i = 0; i < icon.length; i++) {
//     icon[i].addEventListener("click", function () {
//       var current = document.getElementsByClassName("active");
//       if (current.length > 0) {
//         current[0].src = playIcon;
//         current[0].className = current[0].className.replace(" active", "");
//       }
//       this.className += "active";
//       this.src = pauseIcon;
//     });
//   }
// };



const useFunctions = () => {


   const responsive =  {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  // const FilterApi = () =>{
  //   const [FilterAtrr, setFilterAttr ] = useState([]);
  //   axios.get(`${BASE_URL}/filters/?author_slug=hazrat-molana-hakeem-muhammad-akhtar-rh&category_slug=bayanat`)
  //     .then((response) => {
  //       setFilterAttr(response.data);
  //     })
  //     .catch((error) => {
  //       console.log("There was an error!", error);
  //     });
  //   return {FilterAtrr}
  // }


return{responsive};

};

export default useFunctions;