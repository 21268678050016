import React from "react";
import Gif from "../../Images/loader.gif";
import YearFilter from "../filters/year";
import { Helmet } from "react-helmet";
import Pagination from "../Pagination"
import { useHistory } from "react-router-dom";
import useFetch from "../Hooks/useFetch";
import useFilter from "../Hooks/useFilter";
import SocialSharing from "../socialSharing/SocialSharing";

const Books = () => {
  const { dataList, loader } = useFetch("book");
  const history = useHistory();
  const DetailPage = (slug) => {
    history.push(slug);
  };
  const { FilterList } = useFilter();

  return (
    <>
      <div className={loader ? "onloadAnimate" : "onloadAnimate loaded"}>
        <img src={Gif} alt="Loading spinner" />
      </div>
      {dataList.count > 0 && !loader ? (
        <>
          <Helmet>
            <title>
              {dataList.results[0].category.name_en} - Khanqah Imdadia Ashrafia
            </title>
            <meta
              name="description"
              content="dummy text"
            />
          </Helmet>
          <div className="pageTitle">
            <h1>{dataList.results[0].category.name_ur}</h1>
          </div>
          <div className="container">
          <div className="dataFilter row">
            <YearFilter years={dataList.filters?.years} />
          </div>
            <div className="row booksGrid">
              {dataList.results.map((data) => (
                <div className="col-md-2" key={data.id} title={data.description_ur}>
                  <div
                    className="bookCard"
                    onClick={() => {
                      DetailPage("/book/" + data.slug);
                    }}
                  >
                    <SocialSharing link={/book/+data.slug} />
                    <img
                      src={data.files[0]?.thumbnail }
                      alt=""
                    />
                    <h5>{data.title_ur}</h5>
                    <h6>{data.category.name_ur}</h6>
                    <h6>{data.author.name_ur}</h6>
                  </div>
                </div>
              ))}
            </div>
            {dataList.count > 6 && <Pagination data={dataList} />}
          </div>
        </>
      ) : (
        <div className="pageTitle">
          <h1>No Data Found</h1>
        </div>
      )}
    </>
  );
};

export default Books;
