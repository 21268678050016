import React from "react";
import Bg from "../../Images/buttons_bg.png";
import Img1 from "../../Images/asharemarifat.png";
import Bg1 from "../../Images/asharemarifat_bg.png";
import Img2 from "../../Images/book.png";
import Bg2 from "../../Images/book_bg.png";
import Img3 from "../../Images/sotiyat.png";
import Bg3 from "../../Images/sotiyat_bg.png";
import Img4 from "../../Images/majlis.png";
import Bg4 from "../../Images/majlis_bg.png";
import Img5 from "../../Images/moti.png";
import Bg5 from "../../Images/moti_bg.jpg";
import axios from "axios";

import { Link } from "react-router-dom";

const NavButtons = ({live}) => {
  return (
    <div className="navButtons" style={{ backgroundImage: `url(${Bg})` }}>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="navBg">
              <img src={Bg1} alt="" />
            </div>
            <Link
              to="/audios/hazrat-molana-hakeem-muhammad-akhtar-rh/bayanat/"
              className="navContent"
            >
              <img src={Img1} alt="" />
              <h1>بیانات</h1>
            </Link>
          </div>
          <div className="col-md-3">
            <div className="navBg">
              <img src={Bg2} alt="" />
            </div>
            <Link
              to="/books/hazrat-molana-hakeem-muhammad-akhtar-rh/mawaiz-e-hasanah/"
              className="navContent"
            >
              <img src={Img2} alt="" />
              <h1>کتب</h1>
            </Link>
          </div>
          <div className="col-md-3">
            <div className="navBg">
              <img src={Bg3} alt="" />
            </div>
            <Link
              to="/audios/hazrat-molana-hakeem-muhammad-akhtar-rh/short-clips/"
              className="navContent"
            >
              <img src={Img3} alt="" />
              <h1>مختصر صوتیات</h1>
            </Link>
          </div>
          <div className="col-md-3 nasharyat">
            <a target="_blank" href="http://live.khanqah.org:8050/live" >
              <img src={Img4} alt="" />
            </a>
            <Link to="#" className="nasharyat-btn">
              گزشتہ مجالس
            </Link>
            <Link to="#" className="nasharyat-btn">
              مجالس کے اوقات
            </Link>
          </div>
          <div className="col-md-3 onlyMobile">
            <div className="navBg">
              <img src={Bg5} alt="" />
            </div>
            <Link
              to="/audios/hazrat-molana-hakeem-muhammad-akhtar-rh/majlis-e-zikr/"
              className="navContent"
            >
              <img src={Img5} alt="" />
              <h1> مجلس ذکر </h1>
            </Link>
          </div>
          <div className="col-md-3 onlyMobile">
            <div className="navBg">
              <img src={Bg2} alt="" />
            </div>
            <Link
              to="/audios/hazrat-molana-hakeem-muhammad-akhtar-rh/audio-kutub/"
              className="navContent"
            >
              <img src={Img2} alt="" />
              <h1>آڈیو کتب</h1>
            </Link>
          </div>
          <div className="col-md-3 majlis onlyMobile">
            <div className="navBg">
              <img src={Bg4} alt="" />
            </div>
            <a target="_blank" href={live && "http://live.khanqah.org:8002/live"} className="navContent">
              <img src={Img4} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavButtons;
