import React, { useState, useEffect } from "react";
import axios from "axios";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import Gif from "../../Images/loader.gif";
import Pagination from "../Pagination";
import { BASE_URL } from "../../baseUrl";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";

const AnnounceList = () => {
  const { category, filter_url } = useParams();
  const [Content, setContent] = useState();
  const [Loader, setLoader] = useState(true);
  const history = useHistory();
  const is_filter_url = filter_url ? `${filter_url}` : "";
  console.log(Content);
  useEffect(() => {
    setLoader(true);
    axios
      .get(
        `${BASE_URL}/announcement/?category__slug=${category}${is_filter_url}`
      )
      .then((response) => {
        setContent(response.data);
        setLoader(false);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  }, [category, filter_url]);

  const Filter = (e) => {
    history.push(e.target.value);
  };

  return (
    <>
      <div className={Loader ? "onloadAnimate" : "onloadAnimate loaded"}>
        <img src={Gif} alt="Loading spinner" />
      </div>
      {Content?.count > 0 && !Loader ? (
        <>
          <Helmet>
            <title>{category} - Khanqah Imdadia Ashrafia</title>
            <meta name="description" content="dummy text" />
          </Helmet>
          <div className="pageTitle">
            <h1>{Content?.categories[0]}</h1>
          </div>
          <div className="container announcement">
            <div className="dataFilter">
              <div className="fYear">
                <select
                  onChange={(e) => {
                    Filter(e);
                  }}
                >
                  <option value=" ">Filter By Year</option>
                  {Content.filters.dates.map((data) => (
                    <option value={`&posting_date=${data}`} key={data}>
                      {data}
                    </option>
                  ))}
                </select>
                <i className="fa fa-filter" aria-hidden="true"></i>
              </div>
            </div>
            <div className="row">
              {Content?.results.map((data) => (
                <div className="col-md-3">
                  <div class="announceBox">
                    <p className="ReactHtmlParser">
                      {ReactHtmlParser(data.description_ur)}
                    </p>
                    <Link to={`/announcement-detail/${data.id}`}>
                      مزید دیکھیں
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            {Content?.count > 20 && <Pagination data={Content} />}
          </div>
        </>
      ) : (
        <div className="pageTitle">
          <h1>No Data Found</h1>
        </div>
      )}
    </>
  );
};

export default AnnounceList;
