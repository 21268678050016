import React from "react";
import Gif from "../../Images/loader.gif";
import { Helmet } from "react-helmet";
import SocialSharing from "../socialSharing/SocialSharing";
import useFetch from "../Hooks/useFetch";
import PDF from "../../Images/PDF.png"
import TEXT from "../../Images/TEXT.png"
import IMAGE from "../../Images/IMAGE.png"


const bookDetail = () => {
  const { dataList, loader } = useFetch("book");

  return (
    <>
      <div className={loader ? "onloadAnimate" : "onloadAnimate loaded"}>
        <img src={Gif} alt="Loading spinner" />
      </div>
      {dataList.count > 0 && !loader ? (
        <>
          <Helmet>
            <title>
              {dataList.results[0].title_en} - Khanqah Imdadia Ashrafia
            </title>
            <meta name="description" content="dummy text" />
          </Helmet>
          <div className="pageTitle">
            <h1 className="smallTitle">{dataList.results[0].category.name_ur}</h1>
          </div>
          <div className="container">
            <div className="row bookDetail">
              <div className="col-md-6">
                <div className="des_side">
                  <h1>{dataList.results[0].title_ur}</h1>
                  <h4>{dataList.results[0].author.name_ur}</h4>
                  <p>{dataList.results[0].description_ur}</p>
                  <div className="imageIcon">
                    {dataList.results[0]?.files.map((data) => (
                      <>
                        {data.file_type === "PDF" && (
                          <a href={data.file} target="blank">
                            <img
                              src={PDF}
                              alt=""
                            />
                          </a>
                        )}
                      </>
                    ))}
                    {dataList.results[0]?.files.map((data) => (
                      <>
                        {data.file_type === "TEXT" && (
                          <a href={data.file} target="_blank">
                            <img
                              src={TEXT}
                              alt=""
                            />
                          </a>
                        )}
                      </>
                    ))}
                    {dataList.results[0]?.files.map((data) => (
                      <>
                        {data.file_type === "IMAGE" && (
                          <a
                            href={
                              data.file
                            }
                            target="_blank"
                          >
                            <img
                              src={IMAGE}
                              alt=""
                            />
                          </a>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="img_side">
                  <SocialSharing link={"/book/"+dataList.results[0].slug} />
                  <img src={dataList.results[0].files[0]?.thumbnail} alt="" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="pageTitle">
          <h1>No Data Found</h1>
        </div>
      )}
    </>
  );
};
export default bookDetail;
