import React from "react";
import Helmet from "react-helmet";

const KhanqahIntroduction = () => {
  return (
    <>
      <Helmet>
        <title>تعارف - Khanqah Imdadia Ashrafia</title>
        <meta name="description" content="dummy text" />
      </Helmet>
      <div class="pageTitle">
        <h1>تعارف</h1>
      </div>
      <div className="container textPages">
        <div className="row">
          <div className="col-md-12">
            <h1 style={{ marginTop: "2rem" }}>خانقاہ امدایہ اشرفیہ</h1>
            <h5>نقش قدم نبی کے ہیں جنت کے راستے</h5>
            <h5>اللہ سے ملاتے ہیں سنت کے راستے</h5>
            <p>
              بلاشبہ عالم انسانیت کی فلاح و کامرانی کا راز حضور اکرم سرورِ دو
              عالم صلی اللہ علیہ وسلم کی آسمانی و عالمگیر تعلیمات کو صحیح معنوں
              میں سمجھ کر اس پر عمل کرنے میں پوشیدہ ہے۔مسلمانوں نے آپ صلی اللہ
              علیہ وسلم کی تعلیمات کے سائے میں ہمیشہ عروج اور انہیں پس پشت ڈال
              کر ہمیشہ زوال دیکھا۔ اگر غور کیا جائے تو آپ صلی اللہ علیہ وسلم کی
              تعلیمات عملی نقطۂ نظر سے دواقسام پر مشتمل ہیں
            </p>
            <p>
              {" "}
              ۱۔ آپ صلی اللہ علیہ وسلم کی تعلیمات کے پہلے حصہ کا تعلق ظاہری
              اعضاء و جوارح، اعضائے انسانی کے افعال و حرکات اور اُمورِ محسوسہ سے
              ہے، جیسے قیام، تلاوت، رُکوع، سجود، تسبیح، دعوت، جہاد، آداب،
              معاملات اور معاشرت وغیرہ اور یہی حصہ دین کا اصل قالب اور اسلام کا
              عملی نظام ہے۔
            </p>
            <p>
              {" "}
              ۲۔ آپ صلی اللہ علیہ وسلم کی تعلیمات کے دُوسرے حصہ کا تعلق باطنی
              کیفیات سے ہے، جن کو ہم اِخلاص و احتساب، صبر وتوکل، زُہد واِستغناء،
              ایثار و سخاوت، رُوحانی کیفیات، اصلاح اخلاق اورتزکیۂ نفس سے تعبیر
              کرسکتے ہیں، یہ باطنی کیفیات ظاہری اعمال کے لیے لازم وملزوم ہیں۔
              رسول اللہ صلی اللہ علیہ وسلم کی مبارک زندگی میں قیام و قعود ہو یا
              رُکوع و سجود، خانگی معاملات ہوں یا دعوت و تذکیر کے حالات، گھر کا
              ماحول ہو یا میدانِ جہاد، یہ باطنی کیفیات اور قلبی صفات ہر جگہ نظر
              آتی ہیں۔ ان باطنی کیفیات کی ظاہری اعمال کے لیے وہی حیثیت ہے جو
              جسم انسانی کے لیے رُوح کی اور ظاہری ڈھانچے کے مقابلے میں دِل کی۔
              وہ علم جو حصہ اوّل کی تعلیمات پر مشتمل ہے اسے فقہِ ظاہر سے تعبیر
              کیا جاتا ہے اور وہ علم جو دُوسرے حصے کی تعلیمات پر مشتمل ہے اسے
              فقہِ باطن کہا جاتا ہے۔{" "}
            </p>
            <p>
              خیرالقرون ہی سے جہاں ظاہری اعمال پر محنت کی گئی وہیں باطنی اعمال
              کی اصلاح کا بھی نہ صرف مکمل انتظام کیا گیا بلکہ اس کے لئے خانقاہوں
              کاقیام عمل میں آیا اور تزکیہ و اصلاح کا جو کام حضور اکرم صلی اللہ
              علیہ وسلم اور صحابہ رضوان اللہ علیہم اجمعین مسجدِ نبوی میں کیا
              کرتے تھے، ائمہ مجتہدین اور اکابر نے انہیں خانقاہوں میں انجام دیا۔
            </p>
            <h1>خانقاہ کے معنیٰ اور خانقاہ کے قیام کا مقصد</h1>
            <p>
              خانقاہ فارسی زبان کا لفظ ہے اسکے معنیٰ غیاث اللغات میں لکھے
              ہیں’’جائے بودن درویشاں‘‘بس جہاں چند اللہ والے درویش بیٹھ جائیں بس
              وہی خانقاہ ہے
            </p>
            <p>
              خانقاہ کے مقصد کے حوالے سے شیخنا و جدنا و مولانا حضرت اقدس مولانا
              شاہ حکیم محمد اختر صاحب رحمۃ اللہ علیہ فرماتے ہیں کہ خانقاہ
              یُزَکِّیْہِمْ کا مظہر ہے۔ خانقاہ وہ جگہ ہے جہاں ’’جاہ‘‘ کا ’’جیم‘‘
              اور ’’باہ‘‘ کی ’’باء‘‘ نکالی جائے اور خالص ’’آہ‘‘ رہ جائے کیونکہ
              آہ اور اللہ میں کوئی فاصلہ نہیں ہے، ہماری آہ کو اللہ نے اپنی
              آغوش میں لے رکھا ہے۔ جہاں آہ کو جاہ اور باہ سے پاک کیا جائے یعنی
              جہاں جاہ و تکبر مٹایا جائے اور باہ و شہوت، بد نظری اور عشق غیر
              اللہ سے دل کو پاک کیا جائے اس کا نام ’’خانقاہ‘‘ ہے۔ خانقاہ حلوہ
              کھانے کا نام نہیں ہے جیسا کہ عام لوگ سمجھتے ہیں۔ خانقاہ کی تعریف
              پر میرا شعر ہے ؎
            </p>
            <h5>اہلِ دل کے دل سے نکلے آہ آہ</h5>
            <h5>بس وہی اخترؔ ہےاصلی خانقاہ</h5>
            <h1>تزکیہ کے معنیٰ اور اہمیت</h1>
            <p>
              شیخنا و جدنا و مولانا نے ارشاد فرمایا کہ حضرت مولانا شاہ ابرار
              الحق صاحب رحمۃ اللہ علیہ نے فرمایا کہ مدارس سے اور تبلیغ سے اعمال
              کا وجود ملتا ہے اور خانقاہوں سے ببرکتِ اخلاص اعمال کا قبول ملتا
              ہے، تینوں چیزیں ضروری ہیں، مدارس بھی ضروری ہیں، تبلیغ بھی ضروری ہے
              اور تزکیہ بھی ضروری ہے۔ تزکیۂ نفس شعبۂ نبوت ہے۔ بعض نادان لوگ
              کہتے ہیں کہ تزکیہ ولیوں والا کام ہے۔ میں نے کہا یُزَکِّیْھِمْ
              ولیوں والا کام ہے؟ مقاصدِ نبوت میں سے ایک اہم مقصد تزکیہ ہے۔ اللہ
              تعالیٰ نے فرمایا یُزَکِّیْھِمْ۔ ہمارا نبی تزکیہ کرتا ہے اور تزکیہ
              سے مراد طہارتِ قلب، طہارتِ نفس اور طہارتِ بدن ہے۔ اس لیے علامہ
              آلوسی رحمۃ اللہ علیہ نے تفسیر روح المعانی میں یُزَکِّیْھِمْ کی
              تفسیر کی ہے أَیْ یُطَھِّرُ قُلُوْبَھُمْ وَ نُفُوْسَھُمْ وَ
              أَبْدَانَھُم۔ قُلُوْبَھُمْ مِنَ الْاِشْتِغَالِ بِغَیْرِ اللہِ وَ
              نُفُوْسَھُمْ مِنَ الْاَخْلَاقِ الرَّذِیْلَۃِ وَ أَبْدَانَھُمْ
              مِنَ الْأَنْجَاسِ وَ الْاَعْمَالِ الْقَبِیْحَۃِ۔ یعنی نبی پاک
              کرتا ہے صحابہ کے قلوب کو اور ان کے نفوس کو اور ان کے ابدان کو۔قلوب
              کو پاک کرتا ہے غیر اللہ کے اشتغال سے اور نفوس کو پاک کرتا ہے
              اخلاقِ رذیلہ سے اور اجسام کو پاک کرتا ہے نجاستوں سے اور بُرے اعمال
              سے۔ یہ تزکیہ کا حاصل ہے، جب قلب مُزَکِّی ہوتا ہے، نفس مُزَکِّی
              ہوتا ہے اور بدن مُزَکِّی ہوتا ہے تو پھر اللہ کے قرب کی خوشبو زیادہ
              محسوس ہوتی ہے جیسے نہا دھو کر خالص عود لگایا جائے تو عود کی خوشبو
              محسوس ہوتی ہے اور پسینے کی بدبو اور غلاظت اور نجاست پر عود لگاؤ
              تو خوشبو کہاں محسوس ہوگی۔ایسے ہی جو لوگ گناہ نہیں چھوڑتے،نفس کا
              تزکیہ نہیں کراتے، ان کو اللہ کے قرب کی لذت کا صحیح اِدراک نہیں
              ہوتا۔ اورتزکیہ کے بغیر اعمال کا قبول ہی خطرہ میں ہے۔ جیسا کہ حدیثِ
              ریاء میں ہے کہ ایک قاری کو، ایک مجاہد کو اور ایک غنی کو جہنم میں
              پھینک دیا جائے گا، کیونکہ بغیر تزکیہ کے اخلاص نہیں مل سکتا، اسی
              لیے تزکیہ فرض ہے اور تزکیہ فعل متعدی ہے۔ پس مُزَکِّی ہونے کے لیے
              ایک مُزکّیٰ کی ضرورت ہے لہٰذا تزکیہ کے لیے کسی شیخ کامل سے تعلق
              ضروری ہے۔
            </p>
            <h1>
              مدارس ہمیں عالم منزل بناتے ہیں، خانقاہیں اور اللہ والے ہمیں بالغ
              منزل بناتے ہیں
            </h1>
            <p>
              شیخنا و جدنا و مولانا نے ارشاد فرمایا کہ میرے شیخ حضرت مولانا شاہ
              عبد الغنی صاحب پھولپوری رحمۃ اللہ علیہ فرماتے تھے کہ اللہ ملتا ہے
              اللہ والوں سے، اگر یہ بات نہ ہوتی تو مولانا قاسم نانوتوی، مولا نا
              گنگوہی اور بڑے بڑے علماء و مشایخ کے سامنے زانوئے ادب طے نہ کرتے۔
              مشکوٰۃ شریف کے شارح شیخ عبد الحق دہلوی بھی کتراتے کتراتے پھرتے
              تھے، بیعت نہیں ہوتے تھے، پھر ان کے والد نے ان کو خط لکھا، حیاتِ
              عبد الحق میں دیکھ لو، وہ میں نے خود پڑھا ہے، لکھا تھا ’’پسرم ملائے
              خشک و ناہموار نباشی‘‘ اے شیخ عبدالحق، محدث اور مشکوٰۃ شریف کی شرح
              لکھنے والے خشک ملا مت رہ، ناہموار مت رہ، جا کسی اللہ والے کو پکڑ
              اور اس کی صحبت اختیار کر، اللہ کے عاشقوں سے عاشق بنتے ہیں، مدارس
              ہمیں عالم منزل بناتے ہیں، خانقاہیں اور اللہ والے ہمیں بالغِ منزل
              بناتے ہیں، علمِ منزل اور ہے اور بلوغِ منزل اور ہے، علمِ منزل تو یہ
              ہے کہ راستہ دِکھا دیا کہ بھئی یہاں سے ایسے چلے جائیں، وہ فلاں
              گاؤں نظر آرہا ہے، یہ علم ہے مگر ہاتھ پکڑ کر اس گاؤں تک پہنچا دے
              یہ بلوغِ منزل ہے، علم منزل تو مدارس سے حاصل کرلو مگر بالغ منزل وہی
              ہوگا جو کسی بالغ منزل کا ہاتھ پکڑے۔
            </p>
            <h1>خانقاہ امدادیہ اشرفیہ کا قیام</h1>
            <p>
              شیخنا و جدنا و مولانا نے اپنے مشائخ سے جو اللہ تعالیٰ کی محبت،
              دردِ دل اور آہ و فغاں سیکھی تھی اس کو نشر کرنے کے لئے اپنی حیاتِ
              مبارکہ کو وقف فرما دیا تھا، چنانچہ شیخنا و جدنا و مولانا کو ۱۸
              محرم الحرام ۱۳۸۷ھ مطابق ۲۸اپریل ۱۹۶۷ء بروز جمعہ کو حکیم الامت حضرت
              مولانا اشرف علی صاحب تھانوی رحمۃ اللہ علیہ کے خلیفہ حضرت مولانا
              شاہ ابرار الحق صاحب رحمۃ اللہ علیہ نے خلافت اور اجازت بیعت سے
              سرفراز فرمایاتھا۔ اس کے بعدشیخناوجدناومولانا نے اپنی رہائش گاہ
              ۴۔جی ۱/۲ ناظم آبادکراچی سے احیاء السنہ، اصلاح اخلاق اور تزکیہ نفس
              کے عالمگیر کام کا آغاز فرمایا۔ سالکین طریقت اور اہل محبت کی تعداد
              میں مسلسل اضافہ کے باعث جگہ ناکافی ہوگئی۔ شیخنا و جدنا و مولانا کے
              شیخ ثانی حضرت مولانا شاہ ابرار الحق صاحب ہردوئی رحمۃ اللہ علیہ نے
              ہردوئی سےاپنے والا نامہ میں تحریر فرمایا کہ دل میں یوں آتا ہے کہ
              آپ اپنا مکان فروخت کر کے کسی دوسری جگہ زمین لیں اور وہاں خانقاہ
              اور گھربنائیں۔ اپنے شیخ حضرت ہردوئی رحمۃ اللہ علیہ کے مشورہ پر
              شیخناوجدناومولانانے ۵ دسمبر۱۹۷۷ء کو ۶۰۰ گز کا ایک پلاٹ گلشن اقبال
              بلاک ۲ میں خریدا۔۱۰جون ۱۹۷۸ء کو خانقاہ اور گھر کی تعمیر شروع کی
              اورخانقاہ اور گھر کی تعمیر مکمل ہونے پر ۱۲ربیع الاول ۱۴۰۰ھ مطابق
              ۳۱ جنوری ۱۹۸۰ء بروز جمعرات کو دوپہر ساڑھے تین بجے خانقاہ گلشن
              اقبال منتقل ہوگئے۔ خانقاہ میں قرآن پاک کی تعلیم کے لیے ایک مکتب
              قائم فرمایا جس میں بچے قرآن پاک حفظ و ناظرہ کی تعلیم حاصل کرتے
              تھے۔۲۰ اگست ۱۹۸۰ء کواس پلاٹ سے متصل ۷۰۰ گز کا دوسرا پلاٹ مسجد اور
              مدرسہ کی نیت سے خریدا۔ ۲۳ رمضان ۱۴۰۳ھ مطابق ۵ جولائی ۱۹۸۳ء
              بروزمنگل کو مسجد اشرف کا سنگِ بنیاد حضرت مولانا شاہ ابرار الحق
              صاحب ہردوئی رحمۃ اللہ علیہ نے رکھا۔ نہایت طویل جد و جہد اور صبر
              آزما مراحل کے بعد مسجد اشرف کی تعمیر مکمل ہوئی۔ الحمد للہ آج
              خانقاہ امدادیہ اشرفیہ اور مسجد اشرف پورے عالم کا عظیم روحانی مرکز
              ہے جہاں متوسلین، طالبین اور سالکین حضرات خصوصاً بڑے بڑے اہل علم
              افریقہ، امریکہ، برطانیہ، فرانس، جرمنی، برما، بنگلہ دیش، ہندوستان،
              افغانستان، ایران، کینیڈا، سعودی عرب اور عرب امارات وغیرہ اور
              پاکستان کے مختلف علاقوں سے اصلاح و تزکیہ کے لیے حاضر ہوتے ہیں۔ یہ
              وہی جگہ ہے جہاں ایک چھوٹے سے حجرہ میں شیخنا و جدنا و مولانا کا
              قیام تھا اور اسی چھوٹے سے حجرے سے سارے عالم میں دین نشر ہوگیا۔
              احیاء السنہ، اصلاح اخلاق اور تزکیۂ نفس کا کام جو خانقاہ کی اصل
              روح ہے کراچی سے دنیا بھر میں پھیل گیا۔ الحمد للہ آج شیخنا و جدنا
              و مولانا کی نسبت سے مختلف ممالک کے متعدد شہروں میں سینکڑوں
              خانقاہیں قائم ہیں جہاں دین کی اشاعت اور اصلاح و تزکیہ کا کام ہورہا
              ہے۔
            </p>
            <h1>خانقاہ کی نسبت</h1>
            <p>
              شیخنا و جدنا و مولانا نے خانقاہ کو حضرت حاجی امداد اللہ مہاجر مکی
              نور اللہ مرقدہ و حضرت مولانا اشرف علی تھانوی نور اللہ مرقدہ سے
              منسوب کرکے اس کا نام ’’خانقاہ امدادیہ اشرفیہ‘‘ تجویز فرمایا۔ شیخنا
              و جدنا و مولانا نے اپنی خانقاہ کی بنیاد حضرت تھانوی رحمۃ اللہ علیہ
              کے بیان کردہ اصولوں کی روشنی میں رکھی اور تاحیات ان ضوابط پر
              کاربند رہے جس کی رو سے ہمارے بزرگوں کے مزاج میں جو کمال درجہ خشیت
              و للہیت، تقویٰ و طہارت واستغناء تھا، وہ ابتداء ہی سے شیخنا و جدنا
              و مولانا کی ذاتِ اطہر میں بدرجہ اتم موجود تھا۔ چنانچہ شیخنا و جدنا
              و مولانا نے خالصتاً دین کی خدمات کی انجام دہی کے لئے بھی کسی سے
              کوئی تقاضا نہیں کیا اور اللہ تعالیٰ کی راہ میں بذاتِ خود اپنی جان،
              مال، وقت اور رہائش گاہ سب قربان کردیں۔
            </p>
            <h1>خانقاہ کی تعمیرمیں اخلاص تقویٰ اوراحتیاط</h1>
            <p>
              شیخنا و جدنا و مولانا نے ارشاد فرمایا کہ آج سے بیس سال پہلے جب
              میرا مدرسہ بن رہا تھا تو اس وقت ٹھیکیدار نے بتایا کہ چھ لاکھ میں
              ایک منزل بنے گی۔ میرے ایک دوست نواب قیصر صاحب نے کہا کہ عرب ملک کا
              ایک سفیر میرا دوست ہے، میں اس سے بات کرتا ہوں، امید ہے کہ وہ ساری
              رقم کا بندوبست کردے گا۔ سفیر نے نواب قیصرصاحب کی دوستی میں رقم
              دینا قبول کرلی۔ نواب قیصرصاحب نے مجھے فون کیا کہ جلدی سے آکر رقم
              لے لیں تو میں نے کہا کہ آپ جائیں اور ان سے رقم لے کر مجھے پہنچا
              دیں تو انہوں نے کہا کہ اس کے آفس میں جا کر وہاں سائن (Sign) کرنے
              پڑیں گے، تو میں نے کہا کہ دیکھیں نواب صاحب! میں نے بزرگوں کی
              جوتیاں اٹھائی ہیں، اگر بادشاہ کے دروازے پر جا کر پیسہ لیا تو اس
              خانقاہ کی عزت اور پیشانی پر ہمیشہ کے لیے داغ لگ جائے گا کہ اس
              خانقاہ کے فقیر نے ایک بادشاہ کے دروازے پر جاکر پیسہ لیا تھا۔
              حالانکہ مجھے اس وقت رقم کی شدید ضرورت تھی، کے ڈی اے نے نوٹس دے دیا
              تھا کہ اگر مسجد نہیں بناؤ گے تو زمین واپس کرنی پڑے گی پھر بھی میں
              نے کہا کہ نہیں ہم اس طریقے سے پیسہ لینے نہیں جائیں گے۔ نواب صاحب
              نے کہا کہ شاہ عبد الغنی رحمۃ اللہ علیہ کی جوتیاں اٹھانے کی وجہ سے
              اللہ نے تم کو یہ ہمت دی ہے ورنہ اگر آج میں اعلان کردوں تو اس سفیر
              کے دروازے پر پیسہ لینے والوں کی لائن لگ جائے، یہ اللہ تعالیٰ کا
              فضل خاص ہے۔
            </p>
            <p>
              شیخنا و جدنا و مولانا کے ایک قدیم خادم اور خلیفہ مجاز بیعت مولانا
              شہید الاسلام صاحب ہیں،یہ خانقاہ امدادیہ اشرفیہ کی تعمیر کے وقت
              انصار بدنیہ میں شامل تھے ، خانقاہ میں جب موزیک کا فرش بچھا تو
              انہوں نے فرش کی گھسائی اپنے ہاتھ سے کی کیونکہ اس زمانہ میں ماربل
              گھسنے کے لئے مشینیں نہیں ہوتی تھیں۔ مولانا شہید الاسلام صاحب نے
              گزشتہ سال دُبئی کے سفر میں یہ واقعہ سنایا کہ خانقاہ کی تعمیر کے
              دوران حضرت سے محبت رکھنے والے ایک بڑے سرکاری افسر نے پانچ ناریل کے
              بڑے درخت خانقاہ میں لگانے کے لئے ہدیہ کئے، حضرت نے ان کے عہدہ کی
              رعایت کرتے ہوئے اس کو قبول کرلیا، پھر مجھ سے فرمایا کہ معلوم نہیں
              یہ درخت یہ صاحب خرید کر لائے ہیں یا اپنے سرکاری عہدہ کی بنا پر کسی
              نرسری سے بلا قیمت اٹھا کر لے آئے ہیں، میں اپنی خانقاہ میں کوئی
              مشتبہ مال نہیں لگا سکتا، تم ہمارے پیسے سے اتنے ہی بڑے ناریل کے
              درخت تلاش کرکے لاؤ، ہم وہ درخت خانقاہ میں لگائیں گے اور یہ درخت
              کسی کو ہدیہ کردیں گے، اس طرح ان کو بھی تکلیف نہیں ہوگی اور ہمارا
              دل بھی مطمئن ہوجائے گا۔ میں بہت تلاش کے بعد ناریل کے اتنے ہی بڑے
              پانچ درخت خرید کر لایا۔ حضرت نے ان درختوں کو اپنی خانقاہ میں لگایا
              اور ان صاحب کے لائے ہوئے درخت کسی کو ہدیہ کردئیے۔
            </p>
            <p>
              اس اخلاص، تقویٰ اور احتیاط کی برکت سے اللہ تعالیٰ نے اس خانقاہ سے
              نہایت عظیم الشان کام لیے۔ رفتہ رفتہ یہ خانقاہ مرجع خلائق بن گئی
              اور دنیا بھر سے سالکین طریقت بغرض اصلاح جوق در جوق آنے لگے۔
            </p>
            <h1>پیری مریدی کا حاصل</h1>
            <p>
              شیخنا و جدنا و مولانا نے فرمایا کہ پیری مریدی کرنا، شیخ کا دامن
              اور ہاتھ پکڑنا، خانقاہوں میں جانا، بزرگوں سے دعائیں کرانا سب کا
              حاصل یہی ہے کہ بندہ متقی ہو جائے، اگر یہ گناہ نہیں چھوڑتا تو اصلی
              مرید نہیں ہے، اصلی مرید وہ ہے جو ہر وقت یُرِیۡدُوۡنَ وَجۡہَہٗ رہے،
              جو ہر وقت اللہ کو یاد رکھتا ہے۔ لیکن عادۃ اللہ یہی ہے کہ تقویٰ شیخ
              کے ساتھ رہنے سے ملتا ہے، جو اللہ کے لیے شیخ کے ساتھ رہے گا تو اللہ
              تعالیٰ اسے تقویٰ دے دیتے ہیں۔ اس کی دلیل یہ آیت ہے:
            </p>
            <p>
              یٰۤاَیُّہَا الَّذِیۡنَ اٰمَنُوا اتَّقُوْا اللہَ وَ کُوۡنُوۡا مَعَ
              الصّٰدِقِیۡنَ(التوبۃ: 119)
            </p>
            <p>
              اے ایمان والو! تقویٰ سے رہو تاکہ تم میرے ولی بن جاؤ اور تقویٰ
              حاصل کرنے کے لیے اہل تقویٰ کے ساتھ رہو۔
            </p>
            <p>
              شیخ الحدیث حضرت مولانا زکریا صاحب رحمۃ اللہ علیہ نے لکھا ہے کہ
              بغیر شیخ کامل کے گناہ سے بچنا نصیب نہیں ہوتا چاہے کتنا ہی ارادہ
              کرلو۔ خالی علم سے بھی گناہ سے نہیں بچ سکتا، علم روشنی ہے لیکن موٹر
              چلانے کے لیے روشنی کافی نہیں ہوتی پٹرول بھی چاہیے اور وہ ہے اللہ
              کا خوف جو اللہ والوں کے سینوں سے ملتا ہے۔
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default KhanqahIntroduction;
