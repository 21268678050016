import React from "react";
import Gif from "../../Images/loader.gif";
import SocialSharing from "../socialSharing/SocialSharing";
import { Helmet } from "react-helmet";
import useFetch from "../Hooks/useFetch";
import Pagination from "../Pagination";
import { useHistory } from "react-router-dom";
import AudioPlayer from "react-h5-audio-player";
// import LanguageFilter from "../filters/language";

const SearchAll = () => {
  const { dataList, loader } = useFetch("");
  const history = useHistory();
  const DetailPage = (slug) => {
    history.push(slug);
  };
  return (
    <>
      <div className={loader ? "onloadAnimate" : "onloadAnimate loaded"}>
        <img src={Gif} alt="Loading spinner" />
      </div>
      {dataList.count > 0 && !loader ? (
        <>
          <Helmet>
            <title>Search - Khanqah Imdadia Ashrafia</title>
            <meta name="description" content="" />
          </Helmet>
          <div className="pageTitle">
            <h1>تلاش</h1>
          </div>
          <div className="container searchAll">
            <div className="row">
              {dataList.results.map((data) => {
                const {
                  id,
                  title_ur,
                  description_ur,
                  files,
                  slug,
                  media_type,
                } = data;
                return (
                  <>
                    {media_type.type === "Audio" && (
                      <div className="col-md-2" key={id}>
                        <div className="audioStyle" title={description_ur}>
                          <SocialSharing />
                          <div
                            className="audioTitle"
                            onClick={() => {
                              DetailPage("/audio/" + slug);
                            }}
                          >
                            <h5>{title_ur}</h5>
                            {/* {author ? (
                          <h6>{author.name_ur}</h6>
                        ) : (
                          <h6>author not define</h6>
                        )} */}
                          </div>
                          <img
                            src={files[0].thumbnail}
                            alt=""
                            className="thumb"
                            onClick={() => {
                              DetailPage("/audio/" + slug);
                            }}
                          />
                          <AudioPlayer
                            src={files[0].file}
                            showJumpControls={false}
                          />
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
            <div className="row">
              {dataList.results.map((data) => {
                const { media_type } = data;
                return (
                  <>
                    {media_type.type === "Book" && (
                      <div
                        className="col-md-2"
                        key={data.id}
                        title={data.description_ur}
                      >
                        <div
                          className="bookCard"
                          onClick={() => {
                            DetailPage("/book/" + data.slug);
                          }}
                        >
                          <img src={data.files[0]?.thumbnail} alt="" />
                          <h5>{data.title_ur}</h5>
                          <h6>{data.category.name_ur}</h6>
                          <h6>{data.author.name_ur}</h6>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
            <div class="row">
              {dataList.results.map((data) => {
                const { media_type } = data;
                return (
                  <>
                    {media_type.type === "Image" && (
                      <div className="col-md-2 image_pdf" key={data.id}>
                        <SocialSharing share={data.files[0].file} />
                        <img
                          src={data.files[0].thumbnail}
                          alt=""
                          //   onClick={(e) => {
                          //     FullImage(data.files[0].file);
                          //   }}
                        />
                      </div>
                    )}
                  </>
                );
              })}
            </div>
            {dataList.count > 20 && <Pagination data={dataList} />}
          </div>
        </>
      ) : (
        <div className="pageTitle">
          <h1>No Data Found</h1>
        </div>
      )}
    </>
  );
};

export default SearchAll;
