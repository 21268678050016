import { useParams } from "react-router-dom";

const API_URL = () =>{
    const { slug, author, category, filter_url } = useParams();
    const is_slug = slug ? slug : "";
    const is_author = author === "author" ? "":author;
    const is_category = category ? category : "";
    const is_filter_url = filter_url ? filter_url : "";
    const BASE_URL = "https://api.dev.khanqah.org/api/v1";
    const FilterUrl = `https://api.dev.khanqah.org/api/v1/filters/?author_slug=${is_author}&category_slug=${is_category}&media_type__slug=audio`;
    const URL = `${BASE_URL}/post/?author__slug=${!is_author ? "": is_author }&category__slug=${is_category}&slug=${is_slug}${is_filter_url}`;
    return {URL, BASE_URL, FilterUrl};
};
 
export const BASE_URL = "https://api.dev.khanqah.org/api/v1";



export default API_URL;
