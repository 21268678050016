import React, { useState } from "react";
import Gif from "../../Images/loader.gif";
import audioPlayer from "../../Images/audioPlayer.png";
import YearFilter from "../filters/year";
import SocialSharing from "../socialSharing/SocialSharing";
import { Helmet } from "react-helmet";
import useFetch from "../Hooks/useFetch";
import useFilter from "../Hooks/useFilter";
import Pagination from "../Pagination";
import DurationFilter from "../filters/duration";
import "react-h5-audio-player/lib/styles.css";

const Audios = () => {
  const [audioSrc, setaudioSrc] = useState(null);
  const { dataList, loader } = useFetch("audio");
  const { FilterList } = useFilter();

  return (
    <>
      <div className={loader ? "onloadAnimate" : "onloadAnimate loaded"}>
        <img src={Gif} alt="Loading spinner" />
      </div>
      {dataList.count > 0 && !loader ? (
        <>
          <Helmet>
            <title>
              {dataList.results[0].category.name_en} - Khanqah Imdadia Ashrafia
            </title>
            <meta
              name="description"
              content={dataList.results[0].description_en}
            />
          </Helmet>
          <div className="pageTitle">
            <h1>{dataList.results[0].category.name_ur}</h1>
          </div>
          <div className="container">
            <div className="dataFilter">
              <YearFilter years={FilterList.filters?.years} />
              <DurationFilter duration={FilterList.filters?.durations} />
              {/* <LanguageFilter /> */}
            </div>
            <div className="row audio_row" id="playIcon">
              {dataList.results.map((data) => {
                const { id, title_ur, description_ur, files, slug } = data;
                return (
                  <div className="col-md-2" key={id}>
                    <div className="audioStyle" title={description_ur}>
                      <SocialSharing link={"/audio/" + slug} />
                      <div
                        className="audioTitle"
                        onClick={() => {
                          setaudioSrc(data);
                          // DetailPage("/audio/" + slug);
                          setTimeout(() => {
                            document.getElementById("audioSrc").play();
                          }, 500);
                        }}
                      >
                        <h5>{title_ur}</h5>
                        {/* {author ? (
                          <h6>{author.name_ur}</h6>
                        ) : (
                          <h6>author not define</h6>
                        )} */}
                      </div>
                      <img
                        src={files[0].thumbnail}
                        alt=""
                        className="thumb"
                        onClick={() => {
                          setaudioSrc(data);
                          // DetailPage("/audio/" + slug);
                          setTimeout(() => {
                            document.getElementById("audioSrc").play();
                          }, 500);
                        }}
                      />
                      <div className="audioPlayer">
                        <img
                          src={audioPlayer}
                          alt=""
                          onClick={() => {
                            setaudioSrc(data);
                            setTimeout(() => {
                              document.getElementById("audioSrc").play();
                            }, 500);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {dataList.count > 20 && <Pagination data={dataList} />}
          </div>
          {audioSrc && (
            <div id="audioFull">
              <span
                id="closeBtn"
                onClick={() => {
                  setaudioSrc(null);
                }}
              >
                <i className="fa fa-times" aria-hidden="true"></i>
              </span>
              <div className="container">
                <div className="row activeAudio">
                  <div className="col-md-3">
                    <div className="left_col">
                      <SocialSharing link={"/audio/" + audioSrc.slug} />
                      <img
                        src={audioSrc.files[0].thumbnail}
                        alt=""
                        className="thumb"
                      />
                      <audio controls autoplay id="audioSrc">
                        <source
                          src={audioSrc.files[0].file}
                          type="audio/mpeg"
                        />
                      </audio>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="right_col">
                      <h1>{audioSrc.title_ur}</h1>
                      <p>{audioSrc.description_ur}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="pageTitle">
          <h1>No Data Found</h1>
        </div>
      )}
    </>
  );
};

export default Audios;
