import React, { createContext, useContext, useState } from "react";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notification, setnotification] = useState({
    title: null,
    body: null,
    image: null,
  });

  const setNotification = (payload) => {
    if (
      payload.title != null ||
      payload.title != "" ||
      payload.title != notification.title
    ) {
      setnotification(payload);
    }
  };

  return (
    <NotificationContext.Provider value={{ notification, setNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = () => useContext(NotificationContext);

export default useNotification;
