import React from "react";
import useFunctions from "../Hooks/useFunctions";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import { FullImage } from "../Hooks/useFunctions";

const Imageslide = ({ Gallery }) => {
  const { responsive } = useFunctions();

  return (
    <>
      {Gallery[0] && (
        <>
          <h1>{Gallery[0].category.name_ur}</h1>
          <div className="imageSlide">
            <Carousel responsive={responsive} autoPlay={false} infinite={false}>
              {Gallery.map((image) => {
                return (
                  <div key={image.id}>
                    <img
                      src={image.files[0].thumbnail}
                      alt=""
                      onClick={(e) => {
                        FullImage(image.files[0].file);
                      }}
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
          <Link
            to={"/gallery/" + Gallery[0].category.slug + "/"}
            className="dark_btn"
          >
            مزید دیکھیں
          </Link>
        </>
      )}
    </>
  );
};

export default Imageslide;
