import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";


const FeaturedData = ({text}) => {
  const history = useHistory();
  const LinkHit = (e) =>{
    if (e.path === "https://khanqah.org/alabrar/") {
      window.location = e.path;
    } else{
      history.push(`/announcement-detail/${e.id}/`)
    }
  }
  return (
    <>
      <div className="featuredData">
        <span className="featuredTitle">{text.category?.name_ur}</span>
        <p className="featureDataHeight">
        {ReactHtmlParser(text.description_ur)}
        </p>
        {text.image && <img src={text.image} alt="" />}
        <Link to="#" onClick={()=>LinkHit(text)} className="dark_btn">
          مزید دیکھیں
        </Link>
      </div>
    </>
  );
};

export default FeaturedData;
