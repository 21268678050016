import React, {useEffect} from "react";

const HubspotContactForm = ({onClick}) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "6917217",
                    formId: "2f829248-ebe0-4994-9a1b-e5bd5c33f429",
                    target: '#hubspotForm'
                })
            }
        });
    }, []);

    return (
        <div className="popupModal">
            <span onClick={onClick}><i className="fa fa-times" aria-hidden="true"></i></span>
            <div className="hubspotForm">
            <h2>Subscribe now to get the latest updates</h2>
            <p>Enter your email address or other contact information in the subscription form.</p>
            <div id="hubspotForm">
            </div>
            </div>
        </div>
    );

}

export default HubspotContactForm;