import React from "react";
import ReactPaginate from "react-paginate";
import { handlePageClick } from "../Components/Hooks/useFunctions";
import { useHistory } from "react-router-dom";

const Pagination = ({ data }) => {
  const history = useHistory();

  const handlePageClick = (index) => {
    const page = index.selected + 1;
    history.push("&page=" + page);
  };

  return (
    <div className="pagination">
      <ReactPaginate
        breakLabel="..."
        nextLabel="››"
        previousLabel="‹‹"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        forcePage={Math.ceil(data.current_page_number - 1)}
        pageCount={Math.ceil(data.total_pages)}
      />
    </div>
  );
};

export default Pagination;
