import React from "react";
import Gif from "../../Images/loader.gif";
import YearFilter from "../filters/year";
import SocialSharing from "../socialSharing/SocialSharing";
import useFetch from "../Hooks/useFetch";
import useFilter from "../Hooks/useFilter";
import { FullImage, closePopup } from "../Hooks/useFunctions";
import Pagination from "../Pagination";
import { Helmet } from "react-helmet";


const ImageGallery = () => {
  const { dataList, loader } = useFetch("image");
  const { FilterList } = useFilter();

  return (
    <>
      <div className={loader ? "onloadAnimate" : "onloadAnimate loaded"}>
        <img src={Gif} alt="Loading spinner" />
      </div>
      {dataList.count > 0 && !loader ? (
        <>
          <Helmet>
            <title>
              {dataList.results[0].category.name_en} - Khanqah Imdadia Ashrafia
            </title>
            <meta
              name="description"
              content={dataList.results[0].description_en}
            />
          </Helmet>
          <div className="pageTitle">
            <h1>{dataList.results[0].category.name_ur}</h1>
          </div>
          <div className="container">
            <div className="dataFilter">
              <YearFilter years={FilterList.filters?.years} />
            </div>
            <div className="row ImageGallery">
              {dataList.results.map((data) => (
                <div className="col-md-2 image_pdf" key={data.id}>
                  <SocialSharing link={"/gallery/"+data.category.slug} />
                  <img
                    src={data.files[0].thumbnail}
                    alt=""
                    onClick={(e) => {
                      FullImage(data.files[0].file);
                    }}
                  />
                </div>
              ))}
            </div>
            {dataList.count > 6 && <Pagination data={dataList} />}
          </div>
          <div id="fullImage">
            <img src="" alt="" id="changeSrc" />
            <span
              id="closeBtn"
              onClick={() => {
                closePopup();
              }}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </span>
          </div>
        </>
      ) : (
        <div className="pageTitle">
          <h1>No Data Found</h1>
        </div>
      )}
    </>
  );
};

export default ImageGallery;
