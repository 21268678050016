import React, { useState } from "react";
import { Helmet } from "react-helmet";

const Khatoot = () => {
  const [state, setState] = useState({
    name: "",
    email: "",
    city: "",
    country: "",
    age: "",
    relation: "",
    topic: "",
    subject: "",
  });

  const handleChange =(e)=>{
      setState({
          ...state,
          [e.target.name]:e.target.value
      })
  }

  const handleSubmit =(e)=>{
      e.preventDefault();
      console.log(state);
  }

  return (
    <>
      <Helmet>
        <title>اصلاحی خطوط - Khanqah Imdadia Ashrafia</title>
        <meta name="description" content="تحریر اردو اور رومن اردو میں لکھی جاسکتی ہے۔" />
      </Helmet>
      <div className="pageTitle">
        <h1>اصلاحی خطوط</h1>
      </div>
      <div className="container khatoot">
        <div className="row">
          <div className="col-md-12">
            <h1>اصلاحی ای میلز کے لیے قواعدوضوابط</h1>
            <ul dir="rtl">
              <li>تحریر اردو اور رومن اردو میں لکھی جاسکتی ہے۔</li>
              <li>
                ہر مضمون/سوال پرنمبرڈالیں اور غیرضروری باتوں اور تمہید سے اجتناب
                کریں۔(خط کانمونہ منسلک ہے)
              </li>
              <li>
                پچھلی تحریرکی اگراسکین یاسوفٹ کاپی موجود ہو تو اس تحریرکے ساتھ
                منسلک کیجیے۔
              </li>
              <li>ایک ای میل میں تین سے زیادہ سوالات نہ لکھیں۔</li>
              <li>مستورات اپنے محرم کی اجازت سے ای میل کریں۔</li>
              <li>
                مستورات اپنے نام کے بجائے بنت،ہمشیرہ یا زوجہ کے الفاظ استعمال
                کریں۔
              </li>
              <li>
                کم ازکم ہرماہ ایک خط لکھیں/ای میل کریں،خط /ای میل کاجواب کم از
                کم ایک ہفتے میں دیاجاتاہے،اگرتین ہفتےتک جواب موصول نہ ہو تو وہی
                ای میل دوبارہ بھیجیں۔
              </li>
            </ul>
            <form onSubmit={handleSubmit} >
              <label>نام ٭</label>
              <input
                type="text"
                name="name"
                value={state.name}
                onChange={handleChange}
                required
              />
              <label>ای میل ٭</label>
              <input
                type="email"
                name="email"
                value={state.email}
                onChange={handleChange}
                required
              />
              <label>شہر</label>
              <input
                type="text"
                name="city"
                value={state.city}
                onChange={handleChange}
                required
              />
              <label>ملک</label>
              <input
                type="text"
                name="country"
                value={state.country}
                onChange={handleChange}
                required
              />
              <label>عمر</label>
              <input
                type="text"
                name="age"
                value={state.age}
                onChange={handleChange}
              />
              <label>موجودہ اصلاحی تعلق</label>
              <input
                type="text"
                name="relation"
                value={state.relation}
                onChange={handleChange}
              />
              <label>موضوع</label>
              <input
                type="text"
                name="topic"
                value={state.topic}
                onChange={handleChange}
              />
              <label>خط کا مضمون</label>
              <textarea
                name="subject"
                value={state.subject}
                onChange={handleChange}
              ></textarea>
              <button type="submit">پیغام بھیجیں</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Khatoot;
