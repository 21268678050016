import React from "react";

const SocialSharing = ({ link }) => {
  const faceBook = `https://www.facebook.com/sharer/sharer.php?u=https://khanqah.org/${link}`;
  const whatsApp = `whatsapp://send?text=https://khanqah.org${link}`

  return (
    <>
      <div className="socialSharing">
        <a className="share_i" target="_blank">
          <i className="fa fa-share-alt" aria-hidden="true"></i>
        </a>
        <a href={faceBook} className="facebook_i" target="_blank">
          <i className="fa fa-facebook" aria-hidden="true"></i>
        </a>
        <a href={whatsApp} className="whatsapp_i" target="_blank">
          <i className="fa fa-whatsapp" aria-hidden="true"></i>
        </a>
      </div>
    </>
  );
};

export default SocialSharing;
