import React from "react";
import { Helmet } from "react-helmet";

export default function Live2() {
  return (
    <>
      <Helmet>
        <title>
          Live - Khanqah Imdadia Ashrafia
        </title>
      </Helmet>
      <div className="pageTitle">
        <h1 className="detailTitle">
          خانقاہ امدادیہ (گلشن) مہ مجلس شروع ہوگئی
        </h1>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 live-audio">
            <video controls autoplay name="media">
              <source
                src="http://live.khanqah.org:8002/live"
                type="audio/mpeg"
              />
            </video>
          </div>
        </div>
      </div>
    </>
  );
}
