import React from "react";
import { Link } from "react-router-dom";
import temp from "../../Images/temporary.png";
import Home from "./home";

const AhamBayan = ({ text }) => {
  return (
    <div className="ahamBayan">
      <h1>اہم بیان</h1>
      <div className="audioDesign">
        <img src={text.files[0]?.thumbnail} alt="" />
        <audio preload="none" controls>
          <source src={text.files[0]?.file} type="audio/mpeg" />
        </audio>
        <h3>{text.title_ur}</h3>
      </div>
      <h4>{text.author.name_ur}</h4>
      <p>
        کواللہ تعالی انشاه مندا نے اپنی معرفت اور قرب کی ہو بے مثل دولت بخشی تھی
        وہ محتاج بیاں نہیں ۔
      </p>
      <Link
        to="/audios/hazrat-molana-hakeem-muhammad-akhtar-rh/bayanat/"
        className="dark_btn"
      >
        مزید کلپس
      </Link>
    </div>
  );
};

export default AhamBayan;
