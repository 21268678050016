import React from "react";
import { Helmet } from "react-helmet";

const MzSanadehadees = () => {
  return (
    <>
      <Helmet>
        <title>تعارف - Khanqah Imdadia Ashrafia</title>
        <meta name="description" content="dummy text" />
      </Helmet>
      <div class="pageTitle">
        <h1>
          حکیم الامت حضرت اقدس مولانا شاہ حکیم محمد مظہر صاحب دامت برکاتہم
        </h1>
      </div>
      <div className="container textPages">
        <div className="row">
          <div className="col-md-12">
            <div>
              <h1 style={{marginTop:"2rem"}}>سَنَدُ اِجَازَتِ الْحَدِیْثْ</h1>
              <p>
                فضیلۃ الشیخ الحکیم محمد مظہر حفظہ اللہ تعالیٰ <br></br>
                الشیخ محمد عبید اللہ الأمرتسری حفظہ اللہ تعالیٰ <br></br>
                عن الشیخ اشرف علی التہانوی رحمۃ اللہ علیہ <br></br>
                عن الشیخ فضل رحمٰن الکنج مراد آبادی رحمۃ اللہ علیہ <br></br>
                عن الشیخ عبد العزیز الدھلوی رحمۃ اللہ علیہ <br></br>
                عن الشیخ الامام ولی اللہ الدھلوی رحمۃ اللہ علیہ <br></br>
                عن الشیخ ابو طاہر محمد بن ابراہیم رحمۃ اللہ علیہ <br></br>
                عن الشیخ ابراہیم بن حسن کردی رحمۃ اللہ علیہ <br></br>
                عن الشیخ احمد بن محمد قشاشی رحمۃ اللہ علیہ <br></br>
                عن الشیخ احمد بن عبد القدوس شناوی رحمۃ اللہ علیہ <br></br>
                عن الشیخ شمس الدین محمد بن احمد رملی رحمۃ اللہ علیہ <br></br>
                عن الشیخ زین الدین زکریا بن محمد انصاری رحمۃ اللہ علیہ <br></br>
                عن الشیخ شہاب الدین احمد علی بن حجر عسقلانی رحمۃ اللہ علیہ{" "}
                <br></br>
                عن الشیخ ابراہیم بن احمد تنوخی رحمۃ اللہ علیہ <br></br>
                عن الشیخ ابو العباس احمد بن ابی طالب حجاز رحمۃ اللہ علیہ{" "}
                <br></br>
                عن الشیخ حسین بن مبارک زیدی رحمۃ اللہ علیہ <br></br>
                عن الشیخ عبد الاول بن عیسیٰ ہروی رحمۃ اللہ علیہ <br></br>
                عن الشیخ عبد الرحمن بن مظفر داؤدی رحمۃ اللہ علیہ <br></br>
                عن الشیخ محمد عبد اللہ بن احمد السرخسی رحمۃ اللہ علیہ <br></br>
                عن الشیخ ابو عبد اللہ محمد بن یوسف فربری رحمۃ اللہ علیہ{" "}
                <br></br>
                عن الشیخ ابو عبد اللہ محمد بن اسمٰؑعیل بخاری رحمۃ اللہ علیہ{" "}
                <br></br>
                عن الشیخ امام عصام بن خالد رحمۃ اللہ علیہ <br></br>
                عن الشیخ امام حریز بن عثمان رحمۃ اللہ علیہ <br></br>
                عن الشیخ سیدنا عبد اللہ بن بسر رضی اللہ عنہ <br></br>
                عن الشیخ سیدنا سلمہ بن الاکوع رضی اللہ عنہ <br></br>
                عن الشیخ سیدنا ونبینا و هادینا محمد صلی اللہ علیہ وسلم
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MzSanadehadees;