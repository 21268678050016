import React from "react";

const Page_404 = () => {
  return (
    <div className="pageTitle">
      <h1>Page Not Found</h1>
    </div>
  );
};

export default Page_404;