import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./notification.css";
import { useEffect } from "react";
import useNotification from "../Hooks/useNotification";

export default function Notification() {
  const { notification } = useNotification();
  useEffect(() => {
    if (notification.title) {
      handleChange();
    }
  }, [notification.title]);

  const handleChange = () => {
    toast(<CustomToast />, { autoClose: 5000 });
  };

  return (
    <div>
      <ToastContainer className="toast-container" />
    </div>
  );
}

const CustomToast = () => {
  const { notification } = useNotification();
  if (!notification?.title) {
    return null;
  }

  return (
    <>
      <div className="notification">
        <div>
          <img className="logo" src={notification.image} />
        </div>

        <div className="content">
          <h5>{notification.title}</h5>

          <p>{notification.body}</p>
        </div>

        <div className="read">
          <button className="read-button">read</button>
        </div>
      </div>
    </>
  );
};
