import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { FIREBASE_VAPIKEY } from "./config";
import axios from "axios";
import { BASE_URL } from "./baseUrl";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
export const firebaseConfig = {
  apiKey: "AIzaSyCD0Nd5ybw3Wjyb8COUY5cjBXDs1ajfAHE",
  authDomain: "khanqah-cms.firebaseapp.com",
  projectId: "khanqah-cms",
  storageBucket: "khanqah-cms.appspot.com",
  messagingSenderId: "401148459572",
  appId: "1:401148459572:web:fbacb81491b26f4c33e861",
  measurementId: "G-Q6HH42Y73B",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

export const requestPermission = async () => {
  try {
    return await Notification.requestPermission();
  } catch (error) {
    console.error(error);
  }
};

export const getFCMToken = async () => {
  return getToken(messaging, {
    vapidKey: FIREBASE_VAPIKEY,
  })
    .then(async (currentToken) => {
      if (currentToken) {
        console.log("Token: ", currentToken);
        const deviceType = window.innerWidth <= 768 ? "android":"web";
        // Send the token to your server and update the UI if necessary
        axios
          .post(`${BASE_URL}/device/`, {
            name: "",
            device_id: currentToken,
            registration_id: currentToken,
            type: deviceType,
          })
          .then((response) => {
            localStorage.setItem("register", 1);
            console.log("device regestration result =>", response);
          }
          )
          .catch((error) => console.error(error));
        return currentToken;
      } else {
        if ((await requestPermission()) === "granted") {
          console.log("Notification permission granted.");
          //   registerToken();
        } else {
          console.log("Unable to get permission to notify.");
        }
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};
