import React from "react";
import { useHistory, useParams } from "react-router-dom";

const DurationFilter = ({ duration }) => {
  const { filter_url } = useParams();
  const history = useHistory();

  const Filter = () => {
    var year = document.getElementById("fYear").value;
    var duration = document.getElementById("fDuration").value;
    localStorage.setItem("duration", duration);
    history.push(year + duration);
  };

  if (!filter_url) {
    localStorage.setItem("duration", "");
  }

  return (
    <div className="fDuration">
      <select
        id="fDuration"
        value={localStorage.getItem("duration")}
        onChange={() => {
          Filter();
        }}
      >
        <option value="">Filter By Duration</option>
        {duration?.map((data) => (
          <option value={`&duration=${data}`} key={data}>
            {data}
          </option>
        ))}
      </select>
      <i className="fa fa-clock-o" aria-hidden="true"></i>
    </div>
  );
};

export default DurationFilter;
