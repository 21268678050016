import React, { useState, useEffect } from "react";
import axios from "axios";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import Gif from "../Images/loader.gif";
import { BASE_URL } from "../baseUrl";
import ReactHtmlParser from "react-html-parser";


const Information = () => {
  const { author, category } = useParams();
  const [Content, setContent] = useState();
  const [Loader, setLoader] = useState(true);
  console.log(Content)
  useEffect(() => {
    setLoader(true);
    axios
      .get(
        `${BASE_URL}/information/?author__slug=${author}&category__slug=${category}`
      )
      .then((response) => {
        setContent(response.data[0]);
        setLoader(false);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  }, [category, author]);
  return (
    <>
      <div className={Loader ? "onloadAnimate" : "onloadAnimate loaded"}>
        <img src={Gif} alt="Loading spinner" />
      </div>
      <Helmet>
        <title>{category} - Khanqah Imdadia Ashrafia</title>
        <meta name="description" content="dummy text" />
      </Helmet>
      <div className="pageTitle">
        <h1>
        {Content?.title}
        </h1>
      </div>
      <div className="container textPages">
        <div className="row">
          <div className="col-md-12">{ReactHtmlParser(Content?.content)}</div>
        </div>
      </div>
    </>
  );
};

export default Information;
