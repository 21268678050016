import React, { useState } from "react";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [state, setState] = useState({
    name: "",
    email: "",
    subject: "",
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(state);
  };

  return (
    <>
      <Helmet>
        <title>رابطہ - Khanqah Imdadia Ashrafia</title>
        <meta
          name="description"
          content="تحریر اردو اور رومن اردو میں لکھی جاسکتی ہے۔"
        />
      </Helmet>
      <div className="pageTitle">
        <h1>رابطہ</h1>
      </div>
      <div className="container khatoot">
        <div className="row">
          <div className="col-md-12">
            <h1>
              اصلاحی خطوط کے جوابات اور حضرت والادامت برکاتہم سےرابطہ کے لیے
            </h1>
            <ul>
              <li>
                پتہ: خانقاہ امدادیہ اشرفیہ، گلشن اقبال بلاک۲، کراچی، پاکستان-۔
              </li>
              <li>ہپوسٹ بکس نمبر11182</li>
              <li>+92-321-2091000 &nbsp;|&nbsp; 0092.21.34975758 : فون نمبر</li>

              <li>
                <a href="mailto:live@khanqah.org">live@khanqah.org</a>
              </li>
            </ul>
            <ul>
              <li>
                پتہ: خانقاہ امدادیہ اشرفیہ سندھ بلوچ کوآپریٹو ہاؤسنگ سوسائٹی، گلستان جوہر بلاک 12 کراچی، پاکستان
              </li>
              <li>ہپوسٹ بکس نمبر11182</li>
              <li>+92-316-7771053 &nbsp;|&nbsp; +92-321-2091000 : فون نمبر</li>
              <li>
                <a href="mailto:islah@khanqah.org">islah@khanqah.org</a>
              </li>
            </ul>
            <h3>خانقاہ کی ویب سائٹ سے متعلق تجاویز وآراء کے لیے</h3>
            <ul dir="rtl">
              <li>
                <a href="mailto:web.editor@khanqah.org">
                  web.editor@khanqah.org
                </a>
              </li>
              <li>0092.21.34972080</li>
            </ul>
            <h3>ناظم شعبہ نشرواشاعت سے رابطے کے لیے</h3>
            <p>
              شیخ العرب والعجم عارف باللہ مجدد زمانہ حضرت اقدس مولانا شاہ حکیم
              محمد اختر صاحب راللہ قدہ کی کتب اور مختلف زبانوں میں تراجم حلیم
              الامت حضرت اقدس مولانا شاہ حکیم محمد مظہر صاحب دامت برکاتہم کی کتب
              اور مختلف بانوں یں تراجم حضرت والارحمۃ اللہ علیہ کے اکابرین و
              مشائخ کی کتب حاصل کرنے کے لیے ماہنامہ الابراراور سہ ماہی فغان اختر
              کے بارے میں تجاویزاور اغلاط کی نشاندہی کے لیے
            </p>
            <ul dir="rtl">
                <li>پتہ: ناظم شعبہ نشرواشاعت ،خانقاہ امدادیہ اشرفیہ،بلاک ۲،گلشن اقبال ،کراچی،پاکستان ۔</li>
                <li>پوسٹ بکس نمبر 11182</li>
                <li><a href="mailto:editor@khanqah.org">editor@khanqah.org</a></li>
                <li>0092.21.34972080</li>
                <li>0092.321.2091000</li>
            </ul>
            <p>موبائل یا ای میل پر ایڈریس بھیج کر گھر بیٹھے کتابیں مفت منگواسکتے ہیں</p>
            <form onSubmit={handleSubmit}>
              <label>نام ٭</label>
              <input
                type="text"
                name="name"
                value={state.name}
                onChange={handleChange}
                required
              />
              <label>ای میل ٭</label>
              <input
                type="email"
                name="email"
                value={state.email}
                onChange={handleChange}
                required
              />
              <label>خط کا مضمون</label>
              <textarea
                name="subject"
                value={state.subject}
                onChange={handleChange}
              ></textarea>
              <button type="submit">پیغام بھیجیں</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;
