import { React, useState, useEffect } from "react";
import axios from "axios";
import API_URL from "../../baseUrl";

const useFetch = (type) => {
  const [dataList, setdataList] = useState([]);
  const {URL}= API_URL();
  const [loader, setloader] = useState(true);
  useEffect(() => {
    setloader(true);
    axios.get(`${URL}&media_type__slug=${type}`)
      .then((response) => {
        setdataList(response.data);
        setloader(false);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.log("There was an error!", error);
        setloader(false);
      });
  }, [URL]);

  return {dataList, loader};
};

export default useFetch;
