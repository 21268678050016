import React, { useEffect, useState } from "react";
import axios from "axios";
import API_URL from "../../baseUrl";


const useHome = () => {
    const [Home, setHome] = useState([]);
    const [loader, setloader] = useState(true);
    const{BASE_URL} = API_URL();
    useEffect(() => {
      axios
        .get(`${BASE_URL}/universal-api/`)
        .then((response) => {
          setHome(response.data);
          setloader(false)
        })
        .catch((error) => {
          console.log("There was an error!", error);
        });
    }, []);


  return {Home, loader};
}

export default useHome;
