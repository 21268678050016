import React, { useState } from "react";
import audioPlayer from "../../Images/audioPlayer.png";
import Bg from "../../Images/m_sotiyat_bg.jpg";
import useFunctions from "../Hooks/useFunctions";
import Carousel from "react-multi-carousel";
import SocialSharing from "../socialSharing/SocialSharing";
import { Link } from "react-router-dom";

const ShortClips = ({ clips }) => {
  const [audioSrc, setaudioSrc] = useState(null);
  const { responsive } = useFunctions();

  return (
    <>
      <div className="shortClips" style={{ backgroundImage: `url(${Bg})` }}>
        <div className="container">
          <h1>مختصر صوتیات</h1>
          <Carousel responsive={responsive} autoPlay={false} infinite={false}>
            {clips.map((clip) => {
              return (
                <div className="shortClipsBox" key={clip.id}>
                  <h3>{clip.title_ur}</h3>
                  <img
                    src={clip.files[0].thumbnail}
                    onClick={() => {
                      setaudioSrc(clip);
                      // DetailPage("/audio/" + slug);
                      setTimeout(() => {
                        document.getElementById("audioSrc").play();
                      }, 500);
                    }}
                    alt=""
                  />
                  <div className="audioPlayer">
                    <img
                      src={audioPlayer}
                      alt=""
                      onClick={() => {
                        setaudioSrc(clip);
                        setTimeout(() => {
                          document.getElementById("audioSrc").play();
                        }, 500);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </Carousel>
          <Link
            to="/audios/hazrat-molana-hakeem-muhammad-akhtar-rh/short-clips/"
            className="dark_btn"
          >
            مزید مختصر صوتیات
          </Link>
        </div>
      </div>
      {audioSrc && (
        <div id="audioFull">
          <span
            id="closeBtn"
            onClick={() => {
              setaudioSrc(null);
            }}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </span>
          <div className="container">
            <div className="row activeAudio">
              <div className="col-md-3">
                <div className="left_col">
                  <SocialSharing link={"/audio/" + audioSrc.slug} />
                  <img
                    src={audioSrc.files[0].thumbnail}
                    alt=""
                    className="thumb"
                  />
                  <audio controls autoplay id="audioSrc">
                    <source src={audioSrc.files[0].file} type="audio/mpeg" />
                  </audio>
                </div>
              </div>
              <div className="col-md-5">
                <div className="right_col">
                  <h1>{audioSrc.title_ur}</h1>
                  <p>{audioSrc.description_ur}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShortClips;
