import React from "react";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const Banner = ({ slider }) => {
  return (
    <div className="Banner">
      <Carousel responsive={responsive} autoPlay={true} infinite={true}>
        {slider.map((slide) => {
          return (
            <div key={slide.id}>
              {window.innerWidth <= 768 ? (
                <Link to={slide.url}><img src={slide.mobile_image} alt="" /></Link>
              ) : (
                <Link to={slide.url}><img src={slide.web_image} alt="" /></Link>
              )}
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Banner;
